import 'lightcase/src/js/lightcase.js';
import 'select2/dist/js/select2.min.js';
import 'flickity/dist/flickity.pkgd.min.js';
import Flickity from 'flickity';

export default {
  init() {
    // JavaScript to be fired on all pages

    Flickity.defaults.dragThreshold = 3;
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    gallerySlider();
    delayHomeBannerVideo();

    let eventCount = $('#rsvp-page').find('input[name=event-count]').val();
    let maxInvitees = 15;

    for (let index = 1; index <= eventCount; index++) {
      let count = $('#rsvp-page #event-'+index).find('input[name=count]').val();
      let name = $('#rsvp-page #event-'+index).find('input[name=name]').val();
      let date = $('#rsvp-page #event-'+index).find('input[name=date]').val();
      let start_time = $('#rsvp-page #event-'+index).find('input[name=start_time]').val();
      let end_date = $('#rsvp-page #event-'+index).find('input[name=end_date]').val();
      let end_time = $('#rsvp-page #event-'+index).find('input[name=end_time]').val();
      let code = $('#rsvp-page #event-'+index).find('input[name=code]').val();
      $('#rsvp-page #event-'+index).find('input[name=invitee-code').val(code);

      for (let i = 1; i < count + 1; i++) {
        let input = $('#rsvp-page #event-'+index).find('input[name='+i+']').val();
        $('#rsvp-page #event-'+index).find('input[name=invitee-'+i+']').val(input);
        $('#rsvp-page #event-'+index).find('input[name=response-'+i+']').prop('id','custom-switch-'+index+'-'+i);
        $('#rsvp-page #event-'+index+' .invitee-wrp-'+i).find('.custom-control-label').attr('for','custom-switch-'+index+'-'+i);

        $('#rsvp-page #event-'+index).find('select[name=response-dropdown-'+i+']').prop('id','response-dropdown-'+index+'-'+i);
        $('#rsvp-page #event-'+index+' .invitee-wrp-'+i).find('.response-dropdown-label-'+i).prop('id','response-dropdown-label-'+index+'-'+i);

        $('#response-dropdown-'+index+'-'+i).select2({
          dropdownParent: $('#response-dropdown-label-'+index+'-'+i),
        });
      }

      for (let j = maxInvitees; j > count; j--) {
        $('#rsvp-page #event-'+index).find('.invitee-wrp-'+j).hide();
      }

      if (count == 0) {
        // $('#rsvp-page #event-'+index).find('.contact-wrp').hide();
        // $('#rsvp-page #event-'+index).find('.confirm-btn-wrp').hide();
        // $('#rsvp-page #event-'+index+' #rsvp-'+index).prop('disabled',true);
        $('#rsvp-page #event-'+index).hide();
      }

      $('#rsvp-page #event-'+index+' #rsvp-'+index).click(function (e) {
        e.preventDefault();
        $('#rsvp-page #event-'+index+' #form-'+index).slideToggle();
      });

      $(document).on('submit','#rsvp-page #event-'+index+' #form-'+index+' form',function() {
        setTimeout(function() {
          if ($('#rsvp-page #event-'+index+' #form-'+index+' form').hasClass('sent')) {
            $('#rsvp-page #event-'+index+' #rsvp-'+index).text('RSVP SUBMITTED');
            $('#rsvp-page #event-'+index+' #rsvp-'+index).prop('disabled',true);
            $('#rsvp-page #event-'+index+' #form-'+index).toggle();
          }
        }, 3000);
      });

      let config = {
        name: name,
        description: name + ' on ' + date + ' at ' + start_time,
        startDate: date,
        endDate: end_date ? end_date : date,
        startTime: start_time,
        endTime: end_time ? end_time : start_time,
        options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'MicrosoftTeams', 'Yahoo'],
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        trigger: 'click',
        iCalFileName: 'Reminder-Event',
      };
      let button = document.getElementById('add-to-calender-'+index);
      // eslint-disable-next-line no-undef
      button.addEventListener('click', () => atcb_action(config, button));

      $('.form-dropdown-'+index).select2({
        dropdownParent: $('.dropdown-'+index),
      });
    }

    $('header .mobile-menu').click(function(){
      $('body').toggleClass('mobile-menu-active');
    });

    jQuery('a[data-rel^=lightcase]').lightcase({
      slideshowAutoStart: false,
    });
  },
};

$(window).on('resize', function () {
  $('.gallery-section .gallery-wrapper').flickity(
    'resize'
  );
});

function gallerySlider() {
  let $gallerySection = $('.gallery-section .gallery-wrapper');

    if ($gallerySection.length > 0) {

      if (matchMedia('screen and (max-width: 992px)').matches ) {

        var cellAlignValue = ''

        if (matchMedia('screen and (max-width: 768px)').matches ) {
          cellAlignValue = 'center';
        } else {
          cellAlignValue = 'left';
        }

        $gallerySection.flickity({
          contain: false,
          pageDots: false,
          prevNextButtons: false,
          adaptiveHeight: false,
          wrapAround: false,
          cellAlign: cellAlignValue,
          watchCSS: true,
        });
      }
    }
}


// Delay Home Banner Video
function delayHomeBannerVideo() {
  setTimeout(function() {
    $('#home-banner-iframe').attr('src',$('#hidden-iframe-url').text());
    $('#home-banner-video').attr('src',$('#hidden-video-file').text());
  }, 3000);
}
// Delay Home Banner Video
